import api from '@/services/api'
import { useEffect, useState } from 'react'
// eslint-disable-next-line no-restricted-imports
import { formatISO, isSameDay, isBefore, format } from 'date-fns'
import { getUniqueId } from '@/utils/id'
import useTranslation from '@/hooks/translation'
import usePassengersDisplay from '@/hooks/passengers-display'
import useAnalytics from './analytics'
import { getPartnershipsCookie } from '@/utils/cookies'
import { MAINTENANCE } from '@/components/constants'
import Slice from '@/logic/Slice'

class Criteria {
  constructor({ smartFilters }) {
    this.airports = []
    this.carriers = []
    this.departure_hour = []

    if (smartFilters) {
      this.only_direct_flight = smartFilters.only_direct_flight
      this.only_checked_bag_included = smartFilters.only_checked_bag_included
      this.exclude_boeing = smartFilters.exclude_boeing
    }
  }
}

const validateSlice = (slice) => slice.origin && slice.destination && slice.date

const formatSlices = ({ slices, tripType }) => {
  if (tripType === 'oneway') {
    return [
      {
        origin: slices[0].origin.value,
        destination: slices[0].destination.value,
        departure_date: formatISO(slices[0].date, { representation: 'date' }),
      },
    ]
  }

  if (tripType === 'roundtrip') {
    return [
      {
        origin: slices[0].origin.value,
        destination: slices[0].destination.value,
        departure_date: formatISO(slices[0].date, { representation: 'date' }),
      },
      {
        origin: slices[0].destination.value,
        destination: slices[0].origin.value,
        departure_date: formatISO(slices[1].date, { representation: 'date' }),
      },
    ]
  }

  return slices.map((slice) => ({
    origin: slice.origin.value,
    destination: slice.destination.value,
    departure_date: formatISO(slice.date, { representation: 'date' }),
  }))
}

const formatAirport = (airport) => ({
  value: airport.iata_code,
  label: airport.name,
  subLabel: `${airport.city_name} ${airport.country_name}`,
  selected: `${airport.city_name} (${airport.iata_code})`,
})

const offerRequestToStateValues = (offerRequest) => {
  const isRoundTrip =
    offerRequest.slices.length === 2 &&
    offerRequest.slices[0].origin.iata_code ===
      offerRequest.slices[1].destination.iata_code

  const slices = offerRequest.slices.map((slice) =>
    createSlice({
      origin: formatAirport(slice.origin),
      destination: formatAirport(slice.destination),
      date: Slice.parseLocalDate(slice.departure_date),
    })
  )

  // Must always have 2 slices minimum to build search form
  // If offerRequest is oneway, we create another empty slice
  // to handle case where user switch to roundtrip type
  if (slices.length === 1) {
    slices.push(createSlice())
  }

  return {
    cabinClass: offerRequest.cabin_class,
    numAdults: (offerRequest.passengers || []).filter((p) => p.type === 'adult')
      .length,
    numChildren: (offerRequest.passengers || []).filter(
      (p) => p.type === 'child'
    ).length,
    numBabies: (offerRequest.passengers || []).filter(
      (p) => p.type === 'infant'
    ).length,
    tripType:
      offerRequest.type === 'multi'
        ? 'multi'
        : isRoundTrip
        ? 'roundtrip'
        : 'oneway',
    slices,
    partnership: offerRequest.partnership,
  }
}

const isEqual = (a, b) =>
  a instanceof Date
    ? isSameDay(a, b)
    : typeof a === 'object' && a !== null
    ? Object.keys(a)
        .filter((key) => key !== 'id')
        .every((key) => isEqual(a?.[key], b?.[key]))
    : a === b

const isDirty = (offerRequest, state) => {
  if (offerRequest) {
    const offerRequestValues = offerRequestToStateValues(offerRequest)
    return Object.keys(state).some((key) =>
      Array.isArray(state[key])
        ? state[key].some(
            (value, index) => !isEqual(value, offerRequestValues[key][index])
          )
        : !isEqual(state[key], offerRequestValues[key])
    )
  }

  return Object.keys(state).some((key) =>
    Array.isArray(state[key]) ? state[key] > 0 : !!state[key]
  )
}

const createSlice = (slice) => ({
  ...slice,
  id: getUniqueId('search'),
})

function checkValidity(
  tripType,
  slices,
  numAdults,
  numChildren,
  numBabies,
  cabinClass
) {
  return !!(
    (tripType === 'oneway'
      ? validateSlice(slices[0])
      : tripType === 'roundtrip'
      ? validateSlice(slices[0]) && slices[1]?.date
      : slices.every((slice) => validateSlice(slice))) &&
    (numAdults || numChildren || numBabies) &&
    cabinClass
  )
}

const useSearch = (offerRequest, doNotOverridePartnership = false) => {
  const { track } = useAnalytics()
  const [tripType, setTripType] = useState('roundtrip')
  const [slices, setSlices] = useState(() => [createSlice(), createSlice()])
  const [numAdults, setNumAdults] = useState(1)
  const [numChildren, setNumChildren] = useState(0)
  const [numBabies, setNumBabies] = useState(0)
  const [cabinClass, setCabinClass] = useState('economy')
  const [offers, setOffers] = useState([])
  const [bestOffers, setBestOffers] = useState(null)
  const [alternativeOffers, setAlternativeOffers] = useState(null)
  const [partnershipOffers, setPartnershipOffers] = useState(null)
  const [partnership, setPartnership] = useState(null)
  const [flexibleOffers, setFlexibleOffers] = useState(null)
  const [nbOffersPerFilter, setNbOffersPerFilter] = useState(null)

  const { t } = useTranslation()

  useEffect(() => {
    if (offerRequest) {
      const newState = offerRequestToStateValues(offerRequest)

      setCabinClass(newState.cabinClass)
      setNumAdults(newState.numAdults)
      setNumChildren(newState.numChildren)
      setNumBabies(newState.numBabies)
      setSlices(newState.slices)
      setTripType(newState.tripType)
      setOffers([])
      setBestOffers(null)
      setAlternativeOffers(null)
      setPartnershipOffers(null)
      setFlexibleOffers(null)
      if (!doNotOverridePartnership) {
        setPartnership(newState.partnership)
      }
    }
  }, [offerRequest?.id])

  const isValid = checkValidity(
    tripType,
    slices,
    numAdults,
    numChildren,
    numBabies,
    cabinClass
  )

  const getErrors = ({ tripType, slices }) => {
    return {
      slices: slices
        ? slices.map((S) => {
            let e = { id: S.id }
            if (!S.origin) e.origin = t('defaults.forms.required')
            if (!S.destination) {
              e.destination = t('defaults.forms.required')
            }
            if (!S.date) e.date = t('defaults.forms.required')
            return e
          })
        : [],
      missingReturnDate:
        tripType === 'roundtrip' &&
        slices[0].date &&
        !slices[1]?.date &&
        t('hooks.search.errors.missingReturnDate'),
    }
  }

  const passengersDisplay = usePassengersDisplay({
    numAdults,
    numChildren,
    numBabies,
  })

  function setDatesForTripType(newDates, sliceId, currentTripType) {
    setSlices((prevSlices) => {
      const newSlices = [...prevSlices]

      const updateDate = (newDate, sliceId) => {
        const sliceIndex = newSlices.findIndex((slice) => slice.id === sliceId)

        if (sliceIndex >= 0) {
          newSlices.splice(sliceIndex, 1, {
            ...prevSlices[sliceIndex],
            date: newDate,
          })

          const datesToRemoveIndexes = newSlices.reduce(
            (indexes, slice, index) => {
              if (
                index > sliceIndex &&
                slice.date &&
                isBefore(slice.date, newDate)
              ) {
                return [...indexes, index]
              }

              return indexes
            },
            []
          )
          datesToRemoveIndexes.forEach((index) => {
            newSlices.splice(index, 1, {
              ...prevSlices[index],
              date: undefined,
            })
          })
        } else {
          newSlices.push(createSlice({ date: newDate }))
        }
      }

      if (currentTripType === 'roundtrip') {
        updateDate(newDates[0], prevSlices[0].id)
        updateDate(newDates[1], prevSlices[1].id)
      } else {
        updateDate(newDates[0], sliceId)
      }

      return newSlices
    })
  }

  return {
    id: offerRequest?.id,
    isDirty: isDirty(offerRequest, {
      cabinClass,
      numAdults,
      numChildren,
      numBabies,
      slices,
      tripType,
    }),
    isValid,
    errors: getErrors({ tripType, slices }),
    tripType,
    setTripType,
    slices,
    addSlice: ({ origin, destination }) => {
      setSlices((prevSlices) => [
        ...prevSlices,
        createSlice({ origin, destination }),
      ])
    },
    removeSlice: (sliceId) => {
      setSlices((prevSlices) => {
        const newSlices = [...prevSlices]
        const sliceIndex = newSlices.findIndex((slice) => slice.id === sliceId)
        newSlices.splice(sliceIndex, 1)
        return newSlices
      })
    },
    setOrigin: (newOrigin, sliceId) => {
      setSlices((prevSlices) => {
        const newSlices = [...prevSlices]
        const sliceIndex = newSlices.findIndex((slice) => slice.id === sliceId)

        if (sliceIndex >= 0) {
          newSlices.splice(sliceIndex, 1, {
            ...prevSlices[sliceIndex],
            origin: newOrigin,
          })
        } else {
          newSlices.push(createSlice({ origin: newOrigin }))
        }

        return newSlices
      })
    },
    setDestination: (newDestination, sliceId) => {
      setSlices((prevSlices) => {
        const newSlices = [...prevSlices]
        const sliceIndex = newSlices.findIndex((slice) => slice.id === sliceId)

        if (sliceIndex >= 0) {
          newSlices.splice(sliceIndex, 1, {
            ...prevSlices[sliceIndex],
            destination: newDestination,
          })
        } else {
          newSlices.push(createSlice({ destination: newDestination }))
        }

        return newSlices
      })
    },

    setDates: (newDates, sliceId) =>
      setDatesForTripType(newDates, sliceId, tripType),

    setDatesAndTripType: (newDates, sliceId) => {
      const newTripType =
        tripType === 'multi'
          ? tripType
          : newDates.length > 1
          ? 'roundtrip'
          : 'oneway'

      setTripType(newTripType)
      setDatesForTripType(newDates, sliceId, newTripType)
    },

    getMinDate: (sliceId) => {
      const index = slices.findIndex((slice) => slice.id === sliceId)
      return tripType === 'multi' && index > 0
        ? slices
            .slice(0, index)
            .reduce((minDate, slice) => slice.date || minDate, undefined)
        : undefined
    },
    getDates: (sliceId) =>
      tripType === 'roundtrip'
        ? [slices[0]?.date, slices[1]?.date].filter(Boolean)
        : [slices.find((slice) => slice.id === sliceId)?.date].filter(Boolean),
    numAdults,
    setNumAdults,
    numChildren,
    setNumChildren,
    numBabies,
    setNumBabies,
    cabinClass,
    setCabinClass,
    offers,
    setOffers,
    nbOffersPerFilter,
    setNbOffersPerFilter,
    bestOffers,
    alternativeOffers,
    setBestOffers,
    setAlternativeOffers,
    partnershipOffers,
    setPartnershipOffers,
    flexibleOffers,
    setFlexibleOffers,
    partnership,
    totalPassengerCount: numAdults + numChildren + numBabies,
    shortPassengersCabinClassDisplay: `${passengersDisplay}, ${t(
      `hooks.search.cabinClass.${cabinClass}`
    )}`,
    longPassengersCabinClassDisplay: `${passengersDisplay}, ${t(
      `hooks.search.longCabinClass.${cabinClass}`
    )}`,
    setPartnership,
    createSearch: ({
      smartFilters = null,
      ignoreSpecialOffers = false,
      overridedTripType = null,
    }) => {
      if (MAINTENANCE) {
        return Promise.reject({ valid: false })
      }
      let currentTripType = overridedTripType || tripType
      if (
        checkValidity(
          currentTripType,
          slices,
          numAdults,
          numChildren,
          numBabies,
          cabinClass
        )
      ) {
        const partner = getPartnershipsCookie()
        const criteria = new Criteria({ smartFilters })

        // keep only one partner
        const formattedSearch = {
          available_partnerships: partner ? [partner] : [],
          ignore_special_offers: ignoreSpecialOffers,
          type: currentTripType === 'multi' ? 'multi' : 'bundle',
          slices: formatSlices({ slices, tripType: currentTripType }),
          passengers: [
            ...Array(numAdults).fill({ type: 'adult' }),
            ...Array(numChildren).fill({ type: 'child' }),
            ...Array(numBabies).fill({ type: 'infant' }),
          ],
          cabin_class: cabinClass,
          criteria,
        }

        track('prebooking_flight_searched', {
          flight_inbound_airport: formattedSearch.slices[0].origin,
          flight_outbound_airport:
            currentTripType === 'roundtrip'
              ? formattedSearch.slices[0].destination
              : formattedSearch.slices[formattedSearch.slices.length - 1]
                  .destination,
          flight_outbound_date: format(
            Slice.parseLocalDate(formattedSearch.slices[0].departure_date),
            'yyyy-MM-dd'
          ),
          flight_inbound_date:
            formattedSearch.slices.length > 1
              ? format(
                  Slice.parseLocalDate(
                    formattedSearch.slices[formattedSearch.slices.length - 1]
                      .departure_date
                  ),
                  'yyyy-MM-dd'
                )
              : null,
          flight_nb_of_passengers: numAdults + numChildren + numBabies,
          flight_cabin_class: cabinClass,
          origin_airport: formattedSearch.slices[0].origin,
          destination_airport: formattedSearch.slices[0].destination,
        })

        return api.search.createSearch(formattedSearch)
      } else {
        return Promise.reject({ valid: false })
      }
    },
  }
}

export default useSearch
