import Calendar from './Calendar'
import { useState, useCallback } from 'react'
import { formatKeyFromDate } from '@/logic/ContextualCalendarCache'

function getContextFor(ctx, selectedDates, first) {
  if (!ctx) {
    return {}
  }

  if (selectedDates?.length <= 0 || first) {
    return ctx['*'] || {}
  }

  const d = formatKeyFromDate(selectedDates[0])
  // FIXME, returns nothing ???
  return ctx[d] || ctx['*'] || {}
}

function Range({
  selectedDates,
  onSelectedDatesChange,
  className,
  onDisplayedDaysChange,
  contextualData,
}) {
  const [first, setFirst] = useState(true)
  const handleDateSelected = useCallback(
    ({ selectable, date }) => {
      if (!selectable) {
        return
      }

      let dateTime = date.getTime()
      let newDates = [...selectedDates]

      if (selectedDates.length) {
        if (selectedDates.length === 1) {
          let firstTime = selectedDates[0].getTime()

          if (!first && firstTime <= dateTime) {
            newDates.push(date)
          } else {
            newDates = [date]
          }

          onSelectedDatesChange(newDates)
        } else if (newDates.length === 2) {
          onSelectedDatesChange([date])
        }
      } else {
        newDates.push(date)
        onSelectedDatesChange(newDates)
      }
      setFirst(false)
    },
    [selectedDates, onSelectedDatesChange, first, setFirst]
  )

  return (
    <Calendar
      selected={selectedDates}
      onDateSelected={handleDateSelected}
      className={className}
      onDisplayedDaysChange={onDisplayedDaysChange}
      contextualData={getContextFor(contextualData, selectedDates, first)}
      step={first ? 0 : 1}
    />
  )
}

export default Range
