import { useState, forwardRef } from 'react'
import { useCombobox } from 'downshift'

// Components
import Input from '@/components/ui/input'

// eslint-disable-next-line react/display-name
const Autocomplete = forwardRef(
  (
    {
      id,
      label,
      className,
      labelClassName,
      inputClassName,
      onChange,
      children,
      search,
      selectedItem,
      ...rest
    },
    ref
  ) => {
    const [options, setOptions] = useState([])

    const onSearch = async (inputValue) => {
      const results = await search(inputValue)
      setOptions(results || [])
    }

    const [inputValue, setInputValue] = useState(selectedItem?.selected || '')

    const {
      isOpen,
      getLabelProps,
      getMenuProps,
      highlightedIndex,
      getItemProps,
      getInputProps,
      getComboboxProps,
      openMenu,
    } = useCombobox({
      id,
      items: options,
      itemToString: (item) => item?.selected || '',
      inputValue,
      initialSelectedItem: selectedItem,
      selectedItem: selectedItem || null,
      onSelectedItemChange: ({ selectedItem: nextSelectedItem }) => {
        setInputValue(nextSelectedItem?.selected || '')
        onChange(nextSelectedItem)
      },
      onIsOpenChange: ({
        selectedItem: nextSelectedItem,
        isOpen: nextIsOpen,
      }) => {
        if (!nextIsOpen) {
          setInputValue(
            nextSelectedItem?.selected || selectedItem?.selected || ''
          )

          if (!selectedItem) {
            setOptions([])
          }
        }
      },
      onInputValueChange: ({ inputValue: nextInputValue }) => {
        setInputValue(nextInputValue)
        onSearch(nextInputValue)
      },
    })

    return (
      <div {...getComboboxProps({ ref })} className="relative w-full">
        <Input
          {...rest}
          {...getInputProps({
            id,
            onClick: () => {
              openMenu()
            },
            onFocus: (e) => {
              e.target.select()
            },
          })}
          label={label}
          labelProps={getLabelProps()}
          className={className}
          inputClassName={inputClassName}
          labelClassName={labelClassName}
        />
        <div {...getMenuProps()} className="relative z-30">
          {isOpen
            ? children({
                items: options.map((option, index) => ({
                  ...option,
                  getItemProps: (props) =>
                    getItemProps({ item: option, index, ...props }),
                })),
                highlightedIndex,
              })
            : null}
        </div>
      </div>
    )
  }
)

export default Autocomplete
