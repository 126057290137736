class City {
  constructor(city) {
    this.type = 'city'
    Object.assign(this, {
      ...city,
      type: 'city',
    })
  }

  get searchOptionFormat() {
    return {
      type: this.type,
      value: this.code,
      label:
        this.airports.length > 1
          ? 'allAirports'
          : `${this.name}, ${this.country || ''}`,
      subLabel:
        this.airports.length > 1
          ? `${this.name}, ${this.country || ''}`
          : this.airports?.[0]?.name || this.name,
      selected: `${this.name} (${this.code})`,
      name: this.name,
    }
  }
}

export default City
