const idCounter = {}

export const getUniqueId = (prefix) => {
  if (!idCounter[prefix]) {
    idCounter[prefix] = 0
  }

  const id = ++idCounter[prefix]
  return `ulysse${prefix ? `-${prefix}` : ''}-${id}`
}
