import useTranslation from '@/hooks/translation'

const usePassengersDisplay = ({ numAdults, numChildren, numBabies }) => {
  const { t } = useTranslation()

  return `${[
    numAdults
      ? t('hooks.passengersDisplay.adults', { count: numAdults })
      : undefined,
    numChildren
      ? t('hooks.passengersDisplay.children', { count: numChildren })
      : undefined,
    numBabies
      ? t('hooks.passengersDisplay.babies', { count: numBabies })
      : undefined,
  ]
    .filter(Boolean)
    .join(', ')}`
}

export default usePassengersDisplay
