import { useRouter } from 'next/router'
import { MeiliSearch } from 'meilisearch'
import { getLocale } from '@/utils/locale'

export type MeliPlace = {
  code: string
  name: string
  alternative_names: Array<string>
}

export type MeliAirport = MeliPlace & {
  type: string
}

export type MeliCity = MeliPlace & {
  id: string
  country: string
  airports: Array<MeliAirport>

  rank: number
  global_rank: number
  population: number
}

const useMeiliSearch = () => {
  const { locale } = useRouter()

  const client = new MeiliSearch({
    host: process.env.NEXT_PUBLIC_MEILI_URL ?? '',
    apiKey: process.env.NEXT_PUBLIC_MEILI_KEY,
  })

  const cities = client.index<MeliCity>(`cities_${getLocale(locale)}`)

  return {
    cities,
  }
}

export default useMeiliSearch
