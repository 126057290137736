import { useEffect } from 'react'

const useClickOutside = ({ onClickOutside, contentRef, triggerRef }) => {
  const isTargetOutside = (target) =>
    (contentRef?.current ? !contentRef.current.contains(target) : true) &&
    (triggerRef?.current
      ? target !== triggerRef.current && !triggerRef.current.contains(target)
      : true)

  const handleClickOutside = (e) => {
    if (isTargetOutside(e.target)) {
      onClickOutside(e)
    }
  }

  const handleKeyUp = (e) => {
    if (e.key === 'Tab' && isTargetOutside(e.target)) {
      onClickOutside(e)
    }
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    document.addEventListener('keyup', handleKeyUp)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      document.removeEventListener('keyup', handleKeyUp)
    }
  })
}

export default useClickOutside
