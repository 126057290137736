import { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { useGesture } from 'react-use-gesture'
import { useSpring, animated } from '@react-spring/web'
import Button from '@/components/ui/button'
import useBreakpoints from '@/hooks/breakpoints'
import styles from './index.module.css'

function ColSwipe({
  cols,
  hasDesktop = true,
  isDark = false,
  showBullets = true,
  onIndexChange,
  index: indexFromProps,
  tabClassName,
}) {
  const [indexFromState, setIndexFromState] = useState(0)
  const { isTablet, isMobile } = useBreakpoints()

  const isPropsControlled = !!(indexFromProps || indexFromProps === 0)
  const index = isPropsControlled ? indexFromProps : indexFromState
  const setIndex = isPropsControlled ? onIndexChange : setIndexFromState

  const rowRef = useRef()
  const [props, api] = useSpring(() => ({ x: 0 }))

  useEffect(() => {
    const width =
      rowRef.current.getBoundingClientRect().width -
      (isMobile || isTablet ? 70 : 40)
    api.start({
      x: -(index * width),
    })
  })

  const bind = useGesture({
    onDrag: ({
      down,
      movement: [xMovement],
      distance,
      direction: [xDir],
      velocity,
    }) => {
      const width =
        rowRef.current.getBoundingClientRect().width -
        (isMobile || isTablet ? 70 : 40)

      api.start({
        x: down ? xMovement - index * width : -(index * width),
      })

      if (
        !down &&
        (velocity > 0.1 || distance > width / 3) &&
        (xDir <= -0.5 || xDir >= 0.5)
      ) {
        if (xDir > 0) {
          setIndex(index - 1 > 0 ? index - 1 : 0)
        } else {
          setIndex(index + 1 < cols.length ? index + 1 : cols.length - 1)
        }
      }
    },
  })

  useEffect(() => {
    if (onIndexChange && !isPropsControlled) {
      onIndexChange(indexFromState)
    }
  }, [indexFromState])

  return (
    <>
      <div className="sm:hidden">
        {cols.every((col) => col.title?.length) ? (
          <div className="container overflow-x-auto">
            <div className="flex justify-start items-baseline pt-2 pb-10">
              {cols.map((col, i) => (
                <Button
                  key={`title-${i}`}
                  className={clsx(
                    `flex-shrink-0 min-w-1/2 text-left motion-safe:transition-all mr-10 last:mr-20 motion-safe:duration-300 disabled:cursor-default`,
                    {
                      'opacity-100': i === index,
                      'opacity-50': i !== index,
                    },
                    tabClassName
                  )}
                  onClick={() => {
                    setIndex(i)
                  }}
                  disabled={col.disabled}
                  unstyled
                >
                  {col.title}
                </Button>
              ))}
            </div>
          </div>
        ) : null}
        {showBullets ? (
          <div className="pt-10 pb-20 flex justify-center">
            {cols.map((col, i) => (
              <div
                key={`pill-${i}`}
                className={`rounded-full h-8 transition-all duration-300 mr-10 last:mr-0 ${
                  i === index ? 'w-25 opacity-100' : 'w-8 opacity-50'
                } ${isDark ? 'bg-white' : 'bg-dark'}`}
              />
            ))}
          </div>
        ) : null}
        <div className={styles.wrapper} ref={rowRef}>
          <animated.div
            {...bind()}
            style={{ x: props.x }}
            className={styles.dragger}
          >
            {cols.map((col, i) => (
              <div
                key={`col-${i}`}
                className={`${
                  isMobile || isTablet ? styles.colWidthMobile : styles.colWidth
                }`}
              >
                {col.content}
              </div>
            ))}
          </animated.div>
        </div>
      </div>
      {hasDesktop && (
        <div className="hidden sm:block">
          <div className="container">
            <div className="row">
              {cols.map((col, i) => (
                <div key={i} className={`col-${col.width} mb-30`}>
                  {col.title}
                  <div className="mt-15">{col.content}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default ColSwipe
