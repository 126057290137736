import Button from '@/components/ui/button'
import Passengers from '@/components/search/Passengers'
import BackArrow from '@/assets/svg/back-arrow.svg'
import useTranslation from '@/hooks/translation'

export default function PassengersMobile({
  previous,
  onConfirm,
  buttonText,
  isSubmitting,
  ...rest
}) {
  const { numAdults, numChildren, numBabies, cabinClass } = rest

  const { t } = useTranslation()

  return (
    <div className="absolute top-0 right-0 bottom-0 left-0 bg-white flex flex-col z-20">
      <div className="flex items-center border-b border-dark border-opacity-10">
        <Button onClick={previous} className="p-20" unstyled>
          <BackArrow className="opacity-60" />
        </Button>
        <span className="text-17 font-bold py-20 pr-20">
          {t('components.passengersMobile.title')}
        </span>
      </div>
      <div className="bg-white overflow-y-scroll">
        <div className="flex-1 pb-100">
          <Passengers {...rest} />
        </div>
        <div className="fixed bottom-0 left-0 w-full bg-white border-t border-dark border-opacity-20 p-20">
          <Button
            className="w-full"
            size="xl"
            disabled={(!numAdults && !numChildren && !numBabies) || !cabinClass}
            onClick={onConfirm}
            loading={isSubmitting}
          >
            {buttonText || t('components.passengersMobile.buttonDefault')}
          </Button>
        </div>
      </div>
    </div>
  )
}
