import { reportError } from '@/utils/error'
import useMeiliSearch, { MeliCity } from './MeiliSearch'
import City from '@/logic/City'
import Airport from '@/logic/Airport'

export type AirportSearchFilter = (place: City | Airport) => boolean
export const AcceptAll: AirportSearchFilter = () => true
export const OnlyCities: AirportSearchFilter = (place: City | Airport) =>
  place instanceof City
export const OnlyAiports: AirportSearchFilter = (place: City | Airport) =>
  place instanceof Airport

export type SearchListOption = {
  type: string
  value: string
  name?: string
  label: string
  subLabel: string
  selected: string
}

const useAirportSearch = (filter?: AirportSearchFilter) => {
  const { cities } = useMeiliSearch()

  return async (q: string) => {
    if (q?.length > 2) {
      try {
        const results = await cities.search<MeliCity>(q, {
          limit: 50,
        })

        return results.hits.reduce((options: Array<SearchListOption>, hit) => {
          const city = new City(hit)
          const includeCity = filter?.(city) ?? true

          if (includeCity) {
            options.push(city.searchOptionFormat)
          }

          // if the city is not included, we include the airport
          if (hit.airports.length > 1 || !includeCity) {
            hit.airports.forEach((a) => {
              const airport = new Airport(a, hit)
              if (filter?.(airport) ?? true) {
                options.push(airport.searchOptionFormat)
              }
            })
          }

          return options
        }, [])
      } catch (err) {
        console.error(err)
        reportError(err)
        throw err
      }
    }

    return Promise.resolve([])
  }
}

export default useAirportSearch
