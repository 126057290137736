import { useRef, useState, useEffect } from 'react'
import { nextVersion, useMonotonicState } from '@/v2/common/monotonic-state'

// Components
import AirportsList from '@/components/search/AirportsList'
import Button from '@/components/ui/button'

// Icons
import BackArrow from '@/assets/svg/back-arrow.svg'

import Chevron from '@/assets/svg/chevron.svg'

// Hooks
import useAirportSearch from '@/v2/location/AirportSearch'
import useTranslation from '@/hooks/translation'

function searchIndex(res, initial) {
  if (initial && res) {
    for (let i = 0; i < res.length; i++) {
      if (res[i]?.value === initial?.value) {
        return i
      }
    }
  }
  return -1
}

export default function AirportSearchMobile({
  isOrigin,
  onOriginClicked,
  origin,
  destination,
  previous,
  onSelect,
}) {
  const queryInput = useRef(null)
  const [hits, setHits] = useMonotonicState([])
  const [highlightedIndex, setHighlightedIndex] = useState(0)
  const [query, setQuery] = useState('')

  const searchAirports = useAirportSearch()
  const { t } = useTranslation()

  const handleSelect = (val) => {
    onSelect(val)
    setQuery('')
    setHits([])
    setHighlightedIndex(-1)
  }

  useEffect(() => {
    const airport = isOrigin ? origin : destination
    setQuery(airport?.name || airport?.subLabel || airport?.label || '')
    if (queryInput?.current) {
      // FIXME crappy,
      // if we don't wait the animation to stop, the bahavior is wierd
      // we should use a state that tell us that we have the focus
      window.setTimeout(() => queryInput.current.focus(), 300)
    }
  }, [queryInput, isOrigin])

  useEffect(() => {
    const q = query || ''
    if (q.length > 2) {
      const v = nextVersion()
      searchAirports(q).then((res) => {
        setHits(res, v)
        setHighlightedIndex(
          searchIndex(res, isOrigin ? origin : destination),
          v
        )
      })
    } else {
      setHits([])
      setHighlightedIndex(-1)
    }
  }, [query])

  return (
    <div className="fixed top-0 left-0 w-screen h-screen flex flex-col bg-white z-20">
      <header className="flex items-center border-b border-dark-10">
        <Button onClick={previous} className="p-20" unstyled>
          <BackArrow className="opacity-60" />
        </Button>
        {!isOrigin && origin && (
          <div className="flex items-center">
            <Button unstyled onClick={onOriginClicked}>
              <p className="leading-none">{origin.value}</p>
            </Button>
            <div className="text-dark-60">
              <Chevron className="h-10 transform -rotate-90 mx-8" />
            </div>
          </div>
        )}
        <input
          ref={queryInput}
          type="text"
          className="flex-grow py-20 pr-20 font-bold focus:outline-none"
          placeholder={
            isOrigin
              ? t('components.airportSearchMobile.origin')
              : t('components.airportSearchMobile.destination')
          }
          value={query}
          onChange={(e) => {
            setQuery(e.target.value)
          }}
        />
      </header>
      <main className="flex-grow bg-white overflow-y-scroll">
        <AirportsList
          list={hits}
          highlightedIndex={highlightedIndex}
          select={(val) => {
            handleSelect(val)
            setQuery('')
          }}
        />
      </main>
    </div>
  )
}
