import { useCallback, useState } from 'react'
import { differenceInDays } from 'date-fns'
import Range from '@/components/ui/datePicker/Range'
import Single from '@/components/ui/datePicker/Single'
import Button from '@/components/ui/button'
import BackArrow from '@/assets/svg/back-arrow.svg'
import useDates from '@/hooks/dates'
import useTranslation from '@/hooks/translation'

export default function DatesMobile({
  selectedDates,
  previous,
  isRange,
  onSelect,
  minDate,
  contextualData,
  onDepartureDateChange,
}) {
  const { t } = useTranslation()
  const { formatDate } = useDates()
  const [tempDates, setTempDates] = useState(selectedDates)

  const handleSubmit = () => {
    onSelect(tempDates)
  }

  const setDates = useCallback(
    (dates) => {
      setTempDates(dates)
      if (onDepartureDateChange) {
        onDepartureDateChange(dates?.length === 1 ? dates[0] : null)
      }
    },
    [setTempDates, onDepartureDateChange]
  )

  return (
    <div className="fixed top-0 right-0 bottom-0 left-0 bg-white flex flex-col z-20">
      <div className="flex items-center border-b border-dark border-opacity-10">
        <Button onClick={previous} className="p-20" unstyled>
          <BackArrow className="opacity-60" />
        </Button>
        <span className="text-17 font-bold py-20 pr-20 ">
          {tempDates.length === 1 || (tempDates.length === 2 && !isRange)
            ? formatDate(tempDates[0], 'PP')
            : tempDates.length === 2 && isRange
            ? t('components.datesMobile.range', {
                from: formatDate(tempDates[0], 'PP'),
                to: formatDate(tempDates[1], 'PP'),
              })
            : t('components.datesMobile.departureDate')}
        </span>
        {tempDates.length === 2 && isRange ? (
          <span className="text-14 opacity-60 mr-15 ml-auto">
            {t('components.datesMobile.days', {
              count: differenceInDays(tempDates[1], tempDates[0]) + 1,
            })}
          </span>
        ) : null}
      </div>
      <div className="relative flex-1">
        {isRange ? (
          <Range
            isOpen
            selectedDates={tempDates}
            onSelectedDatesChange={setDates}
            minDate={minDate}
            contextualData={contextualData}
          />
        ) : (
          <Single
            isOpen
            selectedDate={tempDates[0]}
            onSelectedDatesChange={setDates}
            minDate={minDate}
            contextualData={contextualData}
          />
        )}
      </div>
      <div className="border-t border-dark border-opacity-20 p-20">
        <Button
          className="w-full"
          size="xl"
          disabled={tempDates?.length <= 0}
          onClick={handleSubmit}
          color={tempDates.length === 1 ? 'light' : 'primary'}
        >
          {tempDates.length < 1
            ? t('components.datesMobile.chooseDeparture')
            : tempDates.length === 1 && isRange
            ? t('components.datesMobile.onlyOneWay')
            : tempDates.length === 1
            ? t('components.datesMobile.chooseDate')
            : t('components.datesMobile.chooseDates')}
        </Button>
      </div>
    </div>
  )
}
