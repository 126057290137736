import Button from '@/components/ui/button'

function SelectionRow({
  label,
  subLabel,
  value,
  onAdd,
  onSubtract,
  min,
  max,
  srValue,
}) {
  return (
    <div className="flex justify-between py-10">
      <div className="flex flex-col justify-center">
        <div className="font-bold text-17" id={`selection-row-label-${label}`}>
          {label}
        </div>
        <div className="text-14 opacity-60 mt-2">{subLabel}</div>
      </div>
      <div className="flex items-center">
        <Button
          outline
          className="h-40 w-40"
          disabled={value <= min}
          onClick={onSubtract}
          aria-label="Subtract 1"
          aria-describedby={`selection-row-label-${label}`}
        >
          <span className="text-22 font-bold">-</span>
        </Button>
        <span className="font-bold px-5 w-35 text-center" aria-hidden>
          {value}
        </span>
        <span className="sr-only" aria-live="polite">
          {srValue}
        </span>
        <Button
          outline
          className="h-40 w-40"
          disabled={value >= max}
          onClick={onAdd}
          aria-label="Add 1"
          aria-describedby={`selection-row-label-${label}`}
        >
          <span className="text-22 font-bold">+</span>
        </Button>
      </div>
    </div>
  )
}

export default SelectionRow
