import Image from 'next/legacy/image'
import clsx from 'clsx'
import Select from '@/components/ui/select'
import SelectionRow from '@/components/SelectionRow'
import useTranslation from '@/hooks/translation'
import Button from '@/components/ui/button'
import Backpack from '@/assets/img/backpack.png'
import Newspaper from '@/assets/img/newspaper.png'
import Champagne from '@/assets/img/champagne.png'
import ColSwipe from '../ui/swiper'
import Trans from '@/components/i18n/Trans'
import Alert from '../ui/alert'

function Row(props) {
  return (
    <div
      className={clsx(
        ' border-dark border-b border-opacity-10 mx-20 md:px-20 md:mx-0',
        {
          'border-none': props.numBabies > 0,
        }
      )}
    >
      <SelectionRow {...props} />
    </div>
  )
}

const CabinClassToggle = ({ label, onSelect, isSelected, image }) => (
  <Button
    className={clsx(
      'p-18 h-100 text-14 rounded-5 shadow ring-1 w-full flex-shrink-0 text-left flex relative snap-center',
      {
        'ring-primary bg-primary-10 text-primary': isSelected,
        'ring-dark-20 text-dark-60': !isSelected,
      }
    )}
    onClick={onSelect}
    aria-pressed={isSelected}
    unstyled
  >
    <Image
      src={image}
      layout="fill"
      objectFit="contain"
      objectPosition="right bottom"
      placeholder="blur"
      alt=""
    />
    {label}
  </Button>
)

export default function Passengers({
  className,
  numAdults,
  numChildren,
  numBabies,
  onNumAdultsChange,
  onNumChildrenChange,
  onNumBabiesChange,
  cabinClass,
  onCabinClassChange,
}) {
  const limit = 9

  const { t } = useTranslation()

  return (
    <div
      className={clsx(
        'bg-white flex flex-col md:mt-5 md:rounded-5 md:shadow-double md:w-350 z-10',
        className
      )}
    >
      <div className="pt-10">
        <Row
          label={t('components.passengers.adultsLabel')}
          subLabel={t('components.passengers.adultsSubLabel')}
          min={1}
          max={limit - numBabies - numChildren}
          value={numAdults}
          srValue={t('components.passengers.adultsSrValue', {
            count: numAdults,
          })}
          onAdd={() => {
            onNumAdultsChange(numAdults + 1)
          }}
          onSubtract={() => {
            onNumAdultsChange(numAdults - 1)

            if (numBabies > numAdults - 1) {
              onNumBabiesChange(numBabies - 1)
            }
          }}
        />
        <Row
          label={t('components.passengers.childrenLabel')}
          subLabel={t('components.passengers.childrenSubLabel')}
          min={0}
          max={limit - numAdults - numBabies}
          value={numChildren}
          srValue={t('components.passengers.childrenSrValue', {
            count: numChildren,
          })}
          onAdd={() => {
            onNumChildrenChange(numChildren + 1)
          }}
          onSubtract={() => {
            onNumChildrenChange(numChildren - 1)
          }}
        />
        <Row
          label={t('components.passengers.babiesLabel')}
          subLabel={t('components.passengers.babiesSubLabel')}
          min={0}
          max={numAdults}
          value={numBabies}
          srValue={t('components.passengers.babiesSrValue', {
            count: numBabies,
          })}
          onAdd={() => {
            onNumBabiesChange(numBabies + 1)
          }}
          onSubtract={() => {
            onNumBabiesChange(numBabies - 1)
          }}
          numBabies={numBabies}
        />
        {numBabies > 0 && (
          <div className="border-b border-dark border-opacity-10 mx-20 md:px-20 md:mx-0 pb-20 ">
            <Alert className="text-14">
              <div>
                <Trans
                  i18nKey="components.passengers.babiesInfos"
                  components={{
                    link: (
                      <a
                        href="mailto:support@ulysse.com"
                        rel="noreferrer noopener"
                        className=" font-bold underline cursor-pointer"
                      />
                    ),
                  }}
                />
              </div>
            </Alert>
          </div>
        )}
      </div>
      <div className="hidden w-full py-30">
        {/* Really wanted to use scroll snap here but ios safari does not do well on overflow scroll inside fixed element / overflow hidden */}
        <ColSwipe
          showBullets={false}
          hasDesktop={false}
          cols={[
            {
              content: (
                <div className="py-3">
                  <CabinClassToggle
                    label={t('components.passengers.cabinClass.economyMobile')}
                    isSelected={cabinClass === 'economy'}
                    onSelect={() => {
                      onCabinClassChange('economy')
                    }}
                    image={Backpack}
                  />
                </div>
              ),
            },
            {
              content: (
                <div className="py-3">
                  <CabinClassToggle
                    label={t(
                      'components.passengers.cabinClass.premium_economy'
                    )}
                    isSelected={cabinClass === 'premium_economy'}
                    onSelect={() => {
                      onCabinClassChange('premium_economy')
                    }}
                    image={Newspaper}
                  />
                </div>
              ),
            },
            {
              content: (
                <div className="py-3">
                  <CabinClassToggle
                    label={t('components.passengers.cabinClass.business')}
                    isSelected={cabinClass === 'business'}
                    onSelect={() => {
                      onCabinClassChange('business')
                    }}
                    image={Newspaper}
                  />
                </div>
              ),
            },
            {
              content: (
                <div className="py-3">
                  <CabinClassToggle
                    label={t('components.passengers.cabinClass.first')}
                    isSelected={cabinClass === 'first'}
                    onSelect={() => {
                      onCabinClassChange('first')
                    }}
                    image={Champagne}
                  />
                </div>
              ),
            },
          ]}
        />
      </div>
      <div className="flex p-20">
        <Select
          id="cabin-class-select"
          label={t('components.passengers.cabinClassLabel')}
          options={[
            {
              value: 'economy',
              label: t('components.passengers.cabinClass.economy'),
            },
            {
              value: 'premium_economy',
              label: t('components.passengers.cabinClass.premium_economy'),
            },
            {
              value: 'business',
              label: t('components.passengers.cabinClass.business'),
            },
            {
              value: 'first',
              label: t('components.passengers.cabinClass.first'),
            },
          ]}
          value={cabinClass}
          onChange={(e) => {
            onCabinClassChange(e.target.value)
          }}
        />
      </div>
    </div>
  )
}
