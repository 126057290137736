import City from './City'

class Airport {
  constructor(airport, city) {
    this.type = 'airport'
    Object.assign(this, {
      ...airport,
      type: airport.type || 'airport',
      city: new City(city),
    })
  }

  get searchOptionFormat() {
    return {
      type: this.type,
      value: this.code,
      label: this.name,
      subLabel: `${this.city?.name}, ${this.city?.country || ''}`,
      selected: `${this.name} (${this.code})`,
    }
  }
}

export default Airport
