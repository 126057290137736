import Plane from '@/assets/svg/places/plane.svg'
import Train from '@/assets/svg/places/train.svg'
import City from '@/assets/svg/places/city.svg'
import useTranslation from '@/hooks/translation'

const icons = {
  airport: <Plane />,
  station: <Train />,
  city: <City />,
}

export default function AirportsList({
  list,
  select,
  highlightedIndex,
  drawIcon = true,
  displayValue = true,
}) {
  const { t } = useTranslation()

  if (!list.length) {
    return null
  }

  return (
    <ul className="px-20 md:p-0">
      {list.map((item, index) => (
        <li
          key={index}
          {...(item.getItemProps
            ? item.getItemProps({
                onClick: select
                  ? () => {
                      select(item)
                    }
                  : undefined,
              })
            : select
            ? {
                onClick: () => {
                  select(item)
                },
              }
            : {})}
          className={`flex justify-between items-center px-0 py-20 md:p-20 border-b last:border-b-0 border-dark-10 w-full text-left hover:bg-dark-10 cursor-pointer ${
            index === highlightedIndex
              ? 'text-primary md:text-dark md:bg-dark-10'
              : ''
          }`}
        >
          <div className="flex items-center gap-10">
            {drawIcon && icons[item.type] ? (
              <div className="opacity-60 w-19 shrink-0">{icons[item.type]}</div>
            ) : null}
            <div>
              <p className="font-bold">
                {item.label === 'allAirports'
                  ? t('components.airportsList.allAirports')
                  : item.label}
              </p>
              <p className="text-14">{item.subLabel}</p>
            </div>
          </div>
          {displayValue && <p className="text-14">{item.value}</p>}
        </li>
      ))}
    </ul>
  )
}
